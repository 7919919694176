import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import {
  AppBar,
  Toolbar,
  Card,
  IconButton,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  Modal,
  Box,
  Typography,
  Button,
} from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProductDetailsModal = ({ product, open, onClose }) => {
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent(
      `Oi, gostaria de saber mais sobre o produto: ${product.name}`,
    );
    const whatsappUrl = `https://api.whatsapp.com/send?phone=+5514996056596&text=${message}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="product-details-title"
      aria-describedby="product-details-description"
    >
      <Box sx={style}>
        <Typography id="product-details-title" variant="h6" component="h2">
          {product.name}
        </Typography>
        <img
          src={product.image}
          alt={product.name}
          style={{ width: "90%", marginTop: "10px", marginBottom: "10px" }}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<WhatsAppIcon />}
          onClick={handleWhatsAppClick}
          style={{ marginTop: "20px" }}
        >
          Pergunte sobre este producto
        </Button>
      </Box>
    </Modal>
  );
};

const ProductList = ({ products }) => {
  const [shuffledProducts, setShuffledProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setShuffledProducts(shuffleArray([...products]));
  }, [products]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        {shuffledProducts.map((product) => (
          <ProductItem
            key={product.id}
            product={product}
            onOpenModal={handleOpenModal}
          />
        ))}
      </Grid>
      {selectedProduct && (
        <ProductDetailsModal
          product={selectedProduct}
          open={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
};

const ProductItem = ({ product, onOpenModal }) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardActionArea onClick={() => onOpenModal(product)}>
          <CardMedia
            component="img"
            height="160"
            image={product.image}
            alt={product.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {product.name}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={
                product.discountedPrice && { textDecoration: "line-through" }
              }
            >
              {product.discountedPrice ? "Preço original":"Preço"}: R$ {product.price.toFixed(2)}
            </Typography>
            {product.discountedPrice && (
              <Typography variant="body1" color="primary">
                Preço promocional: R$ {product.discountedPrice.toFixed(2)}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

const Header = () => {
  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/5514996056596`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <AppBar position="fixed">
      <Toolbar style={{ justifyContent: "center" }}>
        <img
          src="logo_gab.png"
          alt="Logo"
          style={{ height: "50px", width: "50px", marginRight: "10px" }}
        />
        <Typography variant="h6" noWrap style={{ flex: 1 }}>
          Gabriel's Store
        </Typography>
        <IconButton
          color="inherit"
          aria-label="open whatsapp"
          edge="end"
          onClick={handleWhatsAppClick}
        >
          <WhatsAppIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default function App() {
  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ pt: 8, pb: 12 }}>
        <ProductList
          products={[
            {
              id: 1,
              name: "Andador Infantil",
              image: "1.jpeg", // Replace with actual image paths
              price: 350,
              discountedPrice: 300,
            },
            {
              id: 2,
              name: "Protetor de cabeça anticolisão. Nunca usado",
              image: "2.jpeg",
              price: 40,
            },
            {
              id: 3,
              name: "Chinelos 19",
              image: "3.jpeg",
              price: 30,
              discountedPrice: 20,
            },
            {
              id: 4,
              name: "Whoop: adeira de alimentação",
              image: "4.jpeg",
              price: 300,
            },
            {
              id: 5,
              name: "BEBÉ-NIÑO gel de baño suave",
              image: "5.jpeg",
              price: 65,
              discountedPrice: 50,
            },
            {
              id: 6,
              name: "Crocs 19-20 Original",
              image: "6.jpeg",
              price: 80,
            },
            {
              id: 7,
              name: "Bolsa térmica Pucket",
              image: "7.jpeg",
              price: 65,
              discountedPrice: 50,
            },
            {
              id: 8,
              name: "Bolsas saída de maternidade HUG",
              image: "8.jpeg",
              price: 150,
            },
            {
              id: 9,
              name: "Chocalho skip hop abacaxi",
              image: "9.jpeg",
              price: 50,
              discountedPrice: 40,
            },
            {
              id: 10,
              name: "Processador de comida 2 em 1 Fisher Price",
              image: "10.jpeg",
              price: 399,
              discountedPrice: 250,
            },
            {
              id: 11,
              name: "Projetor Tiny Dreamer Boho Chic Tiny Love",
              image: "11.jpeg",
              price: 230,
              discountedPrice: 150,
            },
          ]}
        />
      </Box>
    </>
  );
}
